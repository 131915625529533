#tsparticles canvas {
    background-color: black;
    background-image: url('wallpaper_2.png');
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.button-register {
    border-color: white;
}

#inner {
    animation: scrolltriggeranim 1s ease-in 0s infinite normal forwards;
}
@keyframes scrolltriggeranim {
    0% {
        transform: translateY(0);
    }
    90% {
        transform: translateY(20px);
    }
    100% {
        animation-timing-function: linear;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 600px) {
    #tsparticles canvas {
        background-size: contain;
    }
}